import Stream from './stream'
import { logError } from '../../../../lib/utils/logger'

export default (() => {
  class StreamController {
    constructor({ selector }) {
      this.streams = [...document.querySelectorAll(selector)]
      if (!this.streams) return
      this.streamInstances = this.streams.forEach(s => new Stream(s, this))

      const shouldNcidForward = this.streams.some(stream =>
        stream.classList.contains('ncidForward')
      )
      if (shouldNcidForward) {
        this.initNCIDForwarder(selector)
      }
    }

    initNCIDForwarder(selector) {
      const { ncidForwarder } = window.NEXUtils || {}
      if (!ncidForwarder) {
        logError('Stream', 'NCID Forwarder not found')
        return
      }

      ncidForwarder.addTrackedLinksSelector(`${selector} a`)
    }
  }

  return StreamController
})()
