import Plugins from './plugins'

export default (() => {
  class Stream {
    constructor(elem, controller) {
      if (!elem) return

      this.elem = elem
      this.controller = controller
      this.plugins = []
      this.navCarousel = this.elem.querySelector('.m-nc')
      this.readMoreAuthor = this.elem.querySelector('.read-more')
      this.authorMoreText = this.elem.querySelector('.more-text')
      this.contentBlockCarousel = [
        ...this.elem.querySelectorAll(
          '.m-cb__carousel:not(.m-cb__carousel--js-exclude),.m-cb__carousel--xs-only'
        )
      ]
      this.init()
    }

    init() {
      try {
        const enabledPlugins = JSON.parse(this.elem.dataset.plugins)
        enabledPlugins.forEach(p => {
          this.plugins.push(new Plugins[p](this.elem, this))
        })
      } catch (e) {
        console.error('Error applying plugins to stream')
      }
      if (this.navCarousel) {
        this.plugins.push(new Plugins.GlideCarousel(this.navCarousel, this))
      }
      if (this.contentBlockCarousel.length) {
        this.contentBlockCarousel.forEach(elem => {
          this.plugins.push(new Plugins.Carousel(elem, this))
        })
      }
      this.readMoreAuthor?.addEventListener('click', () => {
        this.readMoreAuthor?.classList.add('hidden')
        this.authorMoreText?.classList.remove('hidden')
      })
    }
  }

  return Stream
})()
